import React, { useEffect, useState } from "react";
import TopBar from "./TopBar/TopBar";
import "./pricegen.css";
import TabContainer from "./TabContainer/TabContainer";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import PriceReview from "./PriceReview/PriceReview";
import { PriceDataProvider } from "../context/PriceDataContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import useActiveMediaQuery from "../hooks/useActiveMediaQuery";
interface CustomPropsData {
  coords?: {
    latitude: number;
    longitude: number;
  };
  description?: string;
  storeId?: string;
  fpisId?: string;
}

import { CompetitorPriceDataProvider } from "../context/CompetitorPriceDataContext";
import { fetchProductMapping } from "../api/mongoEndpoints";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import tableTheme from "../theme/TableTheme";

const PriceGenContainer = ({ customProps }) => {
  const [minimized, setMinimized] = useState(false);
  const [propData, setPropData] = useState<CustomPropsData>({});
  const queryClient = new QueryClient();
  const { maxBoxHeight, minBoxWidth, fontSize } = useActiveMediaQuery();
  const onMinimize = () => {
    setMinimized(!minimized);
  };

  useEffect(() => {
    customProps?.compRef?.mapLayerService?.openPriceGenWindow$?.subscribe(
      (data: CustomPropsData) => {
        if (!data.storeId) {
          return;
        }
        setPropData(data);
      }
    );
    fetchProductMapping().then();
  }, []);

  if (!propData.storeId) {
    return <></>;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Container
        className={
          minimized ? "priceGen-wrapper minimized" : "priceGen-wrapper"
        }
        disableGutters={true}
        sx={{ maxHeight: maxBoxHeight, minWidth: minBoxWidth, fontSize: fontSize}}
      >
        <TopBar
          propData={propData}
          minimized={minimized}
          onMinimize={onMinimize}
          customProps={customProps}
        />

        {!minimized && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              width: "100%",
              height: "auto",
            }}
          >
            <PriceDataProvider
              seiId={propData.storeId}
              fpisStoreId={propData.fpisId}
            >
              <CompetitorPriceDataProvider coords={propData.coords}>
                <Box sx={{ flexGrow: 1 }}>
                  <PriceReview />
                </Box>

                <Box sx={{ flexGrow: 0 }}>
                  <ThemeProvider theme={tableTheme()}>
                    <TabContainer propData={propData} />
                  </ThemeProvider>
                </Box>
              </CompetitorPriceDataProvider>
            </PriceDataProvider>
          </Box>
        )}
      </Container>
    </QueryClientProvider>
  );
};

export default PriceGenContainer;
