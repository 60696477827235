import PGIcon from "./PGIcon";
import MinimizeButton from "./MinimizeButton";
import MaximizeButton from "./MaximizeButton";
import CloseButton from "./CloseButton";
import TextField from "@mui/material/TextField";
import {useState} from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import {Select} from "@mui/material";

const TopBar = ({ minimized, onMinimize, customProps, propData }) => {
    return (
        <div className="title-bar-wrapper">
            <div className="title-bar">
                <div className="title-bar-label">
                    <PGIcon />
                    <div className="title-bar-label-text-wrapper">
                        PriceGen - {propData.storeId} - {propData.description}
                    </div>
                    {/*<Select label="StoreId" value={propData.storeId} onChange={ (event) => {handleChangeStore(event.target.value)} } >*/}
                    {/*    <MenuItem value={"38156"}>38156</MenuItem>*/}
                    {/*    <MenuItem value={"35981"}>35981</MenuItem>*/}
                    {/*    <MenuItem value={"41928"}>41928</MenuItem>*/}
                    {/*</Select>*/}
                </div>
                <div className="title-bar-buttons-wrapper">
                    {minimized ? (
                        <MaximizeButton onMinimize={onMinimize}  />
                    ) : (
                        <MinimizeButton onMinimize={onMinimize} />
                    )}
                    <CloseButton customProps={customProps} />
                </div>
            </div>
        </div>
    );
};

export default TopBar;
