import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import PricegenContainer from "./components/PriceGenContainer";
import {USER_NAME_KEY} from "./util/constants/stringConstants";

const subscribeMock = (callback) => {
  // get storeId and fpisId based on last url path
  const storeId = window.location.pathname.split("/").pop();
  const fallbackStoreId = "21445"
  const mockCustomPropsData = {
    storeId: storeId || fallbackStoreId,
    fpisId: storeId || fallbackStoreId,
    description: "",
    coords: {
      latitude: "29.53902403",
      longitude: "-95.74514151",
    },
  };

  callback(mockCustomPropsData);

  return {
    unsubscribe: () => {},
  };
};

const customPropsMock = {
  compRef: {
    mapLayerService: {
      openPriceGenWindow$: {
        subscribe: subscribeMock,
      },
    },
  },
};

const isLocalhost = window.location.hostname.includes("localhost");

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  // @ts-ignore
  rootComponent: isLocalhost
    ? (props) => <PricegenContainer customProps={customPropsMock} {...props} />
    : PricegenContainer,
  errorBoundary(_, _1, _2) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});
export const { bootstrap, mount, unmount } = lifecycles;
