import useMediaQuery from "@mui/material/useMediaQuery";
import { useState, useEffect } from "react";

const useActiveMediaQuery = () => {
  const under1080p = useMediaQuery("(min-width:1px) and (max-width:1919px)");
  const exactly1080p = useMediaQuery(
    "(min-width:1920px) and (max-width:2559px)"
  );
  const exactly2k = useMediaQuery("(min-width:2560px) and (max-width:3839px)");
  const over2k = useMediaQuery("(min-width:3840px)");

  const [minBoxHeight, setMinBoxHeight] = useState("50vh");
  const [maxBoxHeight, setMaxBoxHeight] = useState("50vh");
  const [minBoxWidth, setMinBoxWidth] = useState("50vw");
  const [fontSize, setFontSize] = useState("14px");

  useEffect(() => {
    if (exactly1080p) {
      setMinBoxHeight("43vh");
      setMaxBoxHeight("56vh");
      setMinBoxWidth("54vw");
    } else if (exactly2k) {
      setMinBoxHeight("31vh");
      setMinBoxWidth("45vw");
    } else if (over2k) {
      setMinBoxHeight("22vh");
      setMaxBoxHeight("36vh");
      setMinBoxWidth("36vw");
      setFontSize("16px");
    }
    // No adjustments for under1080p as it uses the default values
  }, [exactly1080p, exactly2k, over2k]);

  return { minBoxHeight, maxBoxHeight, minBoxWidth, fontSize };
};

export default useActiveMediaQuery;
