import React, { useContext, useEffect, useMemo, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  ListItemAvatar,
  Avatar,
  ButtonBase,
} from "@mui/material";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import EditIcon from "@mui/icons-material/Edit";
import { useFormContext } from "react-hook-form";

const DragAndDropList = ({
  onValidationEdit,
  validationData,
  onReorder,
  dragEnabled,
  showAllValidations,
}) => {
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState("LastDigitNine");

  const {
    formState: { errors },
  } = useFormContext();

  const getItemsFromValidationData = () => {
    const vList = validationData?.validationList.filter(
      (validation) => validation?.validationType != "CompetitorBoundary"
    ); // compBoundary gets configured in strategy config instead of the validation list so don't add it.
    vList.sort((a, b) =>
      a?.priority < b?.priority ? -1 : a?.priority > b?.priority ? 1 : 0
    );
    return vList?.map((x) => ({
      id: `${x?.priority}`,
      primary: `${x?.validationName}`,
      secondary: null, //`${x?.validationDescription}`,
      validationType: `${x?.validationType}`,
    }));
  };

  useEffect(() => {
    let result = [];
    for (let i = 0; i < items?.length; i++) {
      let temp = {
        validationType: items[i].validationType,
        priority: i,
      };
      result.push(temp);
    }

    onReorder(result);
  }, [items]);

  useEffect(() => {
    if (validationData != undefined && validationData != null) {
      console.log("validationData hook triggered.");
      setItems(getItemsFromValidationData());
      // setSelected("LastDigitNine");
    }
  }, [validationData]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const temp = reorder(items, result.source.index, result.destination.index);
    setItems(temp);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
      background: "rgb(235,235,235)",
    }),
  });

  const getListStyle = (isDraggingOver) => ({});

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onSelect = (selection) => {
    setSelected(selection);
    onValidationEdit(selection);
  };

  return (
    <div className="validationListContainer">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <List
              style={getListStyle(snapshot.isDraggingOver)}
              ref={provided.innerRef}
            >
              {items?.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                  isDragDisabled={!dragEnabled}
                >
                  {(provided, snapshot) => (
                    <ButtonBase
                      className={`multiListItem-root ${
                        showAllValidations || item.validationType === selected
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => onSelect(item.validationType)}
                    >
                      <ListItem
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="multiListItem-listItem"
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                        ref={provided.innerRef}
                      >
                        {dragEnabled && (
                          <ListItemIcon>
                            <DragHandleIcon />
                          </ListItemIcon>
                        )}
                        <ListItemText
                          className={`validationDnDItem ${
                            item.validationType in errors ? "hasError" : ""
                          }`}
                          primary={item.primary}
                          // secondary={item.validationType}
                        />
                      </ListItem>
                    </ButtonBase>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default DragAndDropList;
