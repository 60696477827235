import React, {
  createContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useContext, useMemo
} from "react";
import axios from "axios";
import { formServiceUrl } from "../util/apiUrlUtil";
import apiClient from "../util/apiClient";
import { PriceDataContext } from "./PriceDataContext";
import constants from "../util/constants/constants.json";

interface ProductPrice {
  productId: string;
  productName: string;
  price: number;
  effectiveTime: string;
  trigger?: any;
}

interface CompetitorPriceData {
  opisId: string;
  fpisStoreId: string;
  importCode: string;
  displayName: string;
  brandName: string;
  latitude: string;
  longitude: string;
  distance?: any;
  productPrice: ProductPrice[];
}

interface CompetitorPriceContextType {
  initialData: CompetitorPriceData[];
  setInitialData: Dispatch<SetStateAction<CompetitorPriceData[]>>;
  competitorPriceData: CompetitorPriceData[];
  setCompetitorPriceData: Dispatch<SetStateAction<CompetitorPriceData[]>>;
  gridState: "adding" | "removing";
  setGridState: Dispatch<SetStateAction<"adding" | "removing">>;
  distance: string;
  setDistance: Dispatch<SetStateAction<string>>;
  size: number;
  setSize: Dispatch<SetStateAction<number>>;
  fetchData: () => Promise<void>;
}

export const CompetitorPriceDataContext =
  createContext<CompetitorPriceContextType>({
    initialData: [],
    setInitialData: () => {},
    competitorPriceData: [],
    setCompetitorPriceData: () => {},
    gridState: "removing",
    setGridState: () => {},
    distance: "5mi",
    setDistance: () => {},
    size: 25,
    setSize: () => {},
    fetchData: async () => {},
  });

export const CompetitorPriceDataProvider: React.FC<{
  children: React.ReactNode;
  coords?: { latitude: number; longitude: number };
}> = ({ children , coords}) => {
  const [initialData, setInitialData] = useState<CompetitorPriceData[]>([]);
  const [competitorPriceData, setCompetitorPriceData] = useState<CompetitorPriceData[]>([]);
  const [gridState, setGridState] = useState<"adding" | "removing">("removing");
  const [distance, setDistance] = useState<string>("5mi");
  const [size, setSize] = useState<number>(25);

  const priceDataContext = useContext(PriceDataContext);
  const {  priceData, seiId } = priceDataContext;

  const fetchData = async () => {
    if (!priceData || priceData.length === 0) return;
    const serviceUrl = await formServiceUrl(
      constants.urlConstants.priceGenPriceAdminMaster.name,
      constants.urlConstants.priceGenPriceAdminMaster.getCompetitorPriceList
    );
    const payload = {
      seiId: seiId,
      priceGenIdList: priceData.map((a) => a.priceData.latestPriceGenId),
      location: {
        lat: coords?.latitude,
        lon: coords?.longitude,
      },
    };
    try {
      const response = await apiClient.post(serviceUrl, payload);
      setInitialData(response.data);
    } catch (error) {
      console.error("Error fetching competitor prices:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [priceData]);

  const value = useMemo(() => ({
    initialData,
    setInitialData,
    competitorPriceData,
    setCompetitorPriceData,
    gridState,
    setGridState,
    distance,
    setDistance,
    size,
    setSize,
    fetchData,
  }), [initialData, setInitialData, competitorPriceData, setCompetitorPriceData, gridState, setGridState, distance, setDistance, size, setSize, fetchData]);

  return (
    <CompetitorPriceDataContext.Provider value={value}>
      {children}
    </CompetitorPriceDataContext.Provider>
  );
};
