import React, { useContext, useEffect, useRef, useState } from "react";
import CompetitorPrices from "./CompetitorPrices/CompetitorPrices";
import CompetitorGrid from "./CompetitorGrid/CompetitorGrid";
import { PriceDataContext } from "../../context/PriceDataContext";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import HistoryTab from "./HistoryTab/HistoryTab";
import { HistoryTabDataProvider } from "./../../context/HistoryTabContext";
import StrategyEditor from "./Strategy/StrategyEditor";

const TabComponent = ({ propData }) => {
  const priceDataCtx = useContext(PriceDataContext);
  const { selectedPriceData } = priceDataCtx;
  const [value, setValue] = useState(0);
  const tabsRef = useRef(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (tabsRef.current) {
      tabsRef.current.style.setProperty(
        "background-color",
        selectedPriceData?.backgroundColor || "none",
        "important"
      );
    }
  }, [selectedPriceData]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
      }}
    >
      <Box
        sx={{ borderBottom: 1, borderColor: "divider", position: "relative" }}
      >
        <div className="pricegen-tab-container">
          <Tabs
            className="pricegen-tabs"
            value={value}
            onChange={handleChange}
            aria-label="PriceGen Tabs"
            ref={tabsRef}
          >
            <Tab className="pricegen-tab" label="Competitor Prices" />
            <Tab className="pricegen-tab" label="Comp Grid" />
            <Tab className="pricegen-tab" label="Strategy" />
            <Tab className="pricegen-tab" label="History" />
            {/*<Tab className="pricegen-tab" label="Validations" />*/}
            <Typography className="GasGradeText">
              {selectedPriceData?.priceData.productName}
            </Typography>
          </Tabs>
        </div>
      </Box>

      <TabPanel value={value} index={0}>
        <CompetitorPrices />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CompetitorGrid propData={propData} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <StrategyEditor
          storeId={priceDataCtx?.seiId}
          productId={priceDataCtx?.selectedPriceData?.productId}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <HistoryTabDataProvider>
          <HistoryTab />
        </HistoryTabDataProvider>
      </TabPanel>
      {/*<TabPanel value={value} index={4}>*/}
      {/*  <ControlsAndValidations*/}
      {/*    storeId={priceDataCtx?.seiId}*/}
      {/*    productId={priceDataCtx?.selectedPriceData?.productId}*/}
      {/*  />*/}
      {/*</TabPanel>*/}
    </Box>
  );
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export default TabComponent;
