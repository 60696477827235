import { createTheme } from "@mui/material/styles";

export default function tableTheme() {
    return createTheme({
        components: {
            MuiTable: {
                styleOverrides: {
                    root: {
                        "& .MuiTableCell-body": {
                            paddingTop: "0px",
                            paddingBottom: "0px",
                        },
                        "& .MuiTableRow-root:first-child": {
                            paddingTop: "2px",
                        },
                    },
                },
            },
            MuiTableContainer: {
                styleOverrides: {
                    root: {
                        overflowY: "auto",
                        maxHeight: "16vh",
                        // padding: "0.3em",
                        // Add any additional styles you want to standardize
                    },
                },
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        "& .Mui-TableHeadCell-Content": {
                            height: "2em",
                        },
                        "& .MuiTableRow-head": {
                            backgroundColor: "#f0f9f1",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                        },
                        "& .MuiTableCell-root": {
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            paddingRight: "1em",
                        },
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        // fontWeight: "normal",
                        fontSize: "14px",
                        padding: "0px",
                        "& .MuiTableCell-body": {
                            padding: "0px",
                        },
                        "& .Mui-TableHeadCell-ResizeHandle-Wrapper": {
                            backgroundColor: "unset",
                        },
                        "& svg": {
                            fontSize: "12px",
                        },
                        "& .MuiInputBase-formControl": {
                            fontSize: "12px",
                        },
                        "& .MuiMenuItem-root": {
                            fontSize: "12px",
                        },
                        "& .MuiChip-filled": {
                            height: "unset",
                            maxWidth: "unset",
                        },
                        "& .MuiSvgIcon-root": {
                            marginRight: "1em",
                        },
                        "& .MuiCheckbox-root": {
                            padding: "0px",
                        },
                    },
                },
            },
        },
        customColors: {},
    });
}
