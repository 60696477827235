import React, { useContext, useEffect } from "react";
import "./ActionBar.css";
// import { useMutation } from "react-query";
import { getImagePath, getUserName } from "../../util/util";
import { PriceDataContext } from "../../context/PriceDataContext";
import { useMutation } from "@tanstack/react-query";
import {
  callForceGeneratePrice,
  updateStatusForStoreProduct,
} from "../../api/proposedPrices";
import IUpdateStatus from "../../models/priceView/IUpdateStatus";
import { getAuditTrail } from "../../api/auditTrail";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material";
import { TIME_CONFIG } from "../../util/constants/priceViewConfig";
import * as constants from "../../util/constants/stringConstants";

const FORCE_GENERATE_BUTTON_TEXT = "Force Generate";
const FORCE_GENERATE_BUTTON_TEXT_SUCCESS = "✓ Success";
const FORCE_GENERATE_BUTTON_TEXT_FAILURE = "✕ Error";

interface ActionBarProps {
  inputDistance: number;
}

const ActionBar: React.FC<ActionBarProps> = ({ inputDistance }) => {
  const priceDataCtx = useContext(PriceDataContext);
  const [forceGenerateStatus, setForceGenerateStatus] = React.useState<
    "FAILURE" | "SUCCESS" | null
  >(null);
  const [submitStatus, setSubmitStatus] = React.useState<
    "FAILURE" | "SUCCESS" | null
  >(null);

  let fetchExportStatusIntervalId = null;

  // let adjustAllStyle = {
  //   transform: "translateX(0px)",
  // };

  // if (inputDistance) {
  //   adjustAllStyle = {
  //     transform: `translateX(${inputDistance}em)`,
  //   };
  // }

  useEffect(() => {
    let timeoutId = null;

    if (forceGenerateStatus) {
      // Clear any previous timeout to avoid multiple resets
      timeoutId = setTimeout(() => {
        setForceGenerateStatus(null);
      }, 3000);
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [forceGenerateStatus]);

  useEffect(() => {
    let timeoutId = null;

    if (submitStatus) {
      // Clear any previous timeout to avoid multiple resets
      timeoutId = setTimeout(() => {
        setSubmitStatus(null);
      }, 3000);
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [submitStatus]);

  const {
    seiId,
    fpisStoreId,
    priceData,
    adjustProposedPriceForAll,
    fetchGeneratedPrices,
    setPriceData,
    scheduleProposedPricesExportCheck,
  } = priceDataCtx;

  const isSubmitReady = priceData.some(
    (pd) =>
      pd.priceData.latestPriceGenPriceStatus === constants.PENDING &&
      pd.status !== null
  );

  const allPricesFinalized = priceData.every(
    (pd) => pd.disabled || pd.rejected
  );

  useEffect(() => {
    if (fetchExportStatusIntervalId) {
      clearInterval(fetchExportStatusIntervalId);
    }
  }, [priceData]);
  const setForceGenerate = (status: boolean) => {
    setPriceData(
      priceData.map((p) => {
        p.forceGenerating = status;
        return p;
      })
    );
  };

  const { mutate: updateStatus } = useMutation(updateStatusForStoreProduct, {
    onSuccess: (data) => {
      console.debug("Proposed prices updated successfully: ", data);
      fetchGeneratedPrices();
      setSubmitStatus(constants.SUCCESS);
      fetchExportStatusIntervalId = setInterval(() => {
        scheduleProposedPricesExportCheck();
      }, TIME_CONFIG.FETCH_PROPOSED_PRICES_EXPORT_INTERVAL);
      setTimeout(() => {
        clearInterval(fetchExportStatusIntervalId);
      }, TIME_CONFIG.FETCH_PROPOSED_PRICES_EXPORT_TIMEOUT);
    },
    onError: (error) => {
      console.error("Error updated proposed prices:", error);
      setSubmitStatus(constants.FAILURE);
    },
  });

  const { mutate: forceGeneratePrices } = useMutation(
    () => callForceGeneratePrice(fpisStoreId, seiId),
    {
      onSuccess: (data: string[]) => {
        const intervalId = setInterval(async () => {
          try {
            let priceGenIds = data;
            const auditStatusMap = await getAuditTrail(priceGenIds);
            const isSuccess = priceGenIds.some(
              (id) =>
                auditStatusMap[id].priceGenFlowStatus === constants.SUCCESS
            );
            const somePresent = priceGenIds.some(
              (id) =>
                auditStatusMap[id].priceGenFlowStatus !== constants.NOT_PRESENT
            );
            if (!somePresent) {
              return;
            }
            if (isSuccess) {
              setForceGenerateStatus(constants.SUCCESS);
            } else {
              return;
            }
            clearInterval(intervalId);
            clearTimeout(timeoutId);
            fetchGeneratedPrices();
            setForceGenerate(false);
          } catch (error) {
            clearInterval(intervalId);
            clearTimeout(timeoutId);
          }
        }, TIME_CONFIG.DEFAULT_FETCH_INTERVAL);
        const timeoutId = setTimeout(() => {
          clearInterval(intervalId);
          setForceGenerate(false);
          setForceGenerateStatus(constants.FAILURE);
        }, TIME_CONFIG.FETCH_FORCE_GENERATE_TIMEOUT);
      },
      onError: (error) => {
        console.error("Error generating prices:", error);
        setForceGenerate(false);
        setForceGenerateStatus(constants.FAILURE);
      },
    }
  );

  const handleForceGenerate = () => {
    console.debug("Forcing generate prices");
    // set priceData items to forceGenerating = true
    setForceGenerate(true);
    forceGeneratePrices();
  };

  const handleSubmit = () => {
    const updateStatusArray: IUpdateStatus[] = [];
    setPriceData(
      priceData.map((p) => {
        p.submitting = true;
        return p;
      })
    );

    priceDataCtx.priceData
      .filter((item) => item.status !== null)
      .forEach((item) => {
        updateStatusArray.push({
          modifiedBy: getUserName(),
          newStatus: item.status,
          price: item.status === constants.APPROVED ? item.proposedPrice : null,
          priceGenId: item.priceData.latestPriceGenId,
          statusComments: item.priceData.statusComments,
        });
      });
    updateStatus(updateStatusArray);
  };
  return (
    <div className="action-bar" onClick={(e) => e.preventDefault()}>
      <div className={`adjust-all ${allPricesFinalized ? "disabled" : ""}`}>
        <span className="label" style={{ fontWeight: "bold" }}>
          All up/down
        </span>
        <div className="button-container">
          <button
            className="increment"
            onClick={() => adjustProposedPriceForAll(true)}
            aria-label="Increment"
            disabled={priceData.every((item) => item.disabled)}
          >
            <img src={getImagePath("up")} alt="Increment" />
          </button>
          <button
            className="decrement"
            onClick={() => adjustProposedPriceForAll(false)}
            aria-label="Decrement"
            disabled={priceData.every((item) => item.disabled)}
          >
            <img src={getImagePath("down")} alt="Decrement" />
          </button>
        </div>
      </div>

      <div className="action-buttons button-container">
        <ForceGenerateButton
          // className="force-generate"
          aria-label={FORCE_GENERATE_BUTTON_TEXT}
          size="small"
          variant="outlined"
          color="success"
          onClick={handleForceGenerate}
          loading={priceData.some((item) => item.forceGenerating)}
          status={forceGenerateStatus}
          disabled={forceGenerateStatus !== null}
        >
          {forceGenerateStatus === constants.SUCCESS
            ? FORCE_GENERATE_BUTTON_TEXT_SUCCESS
            : forceGenerateStatus === constants.FAILURE
            ? FORCE_GENERATE_BUTTON_TEXT_FAILURE
            : FORCE_GENERATE_BUTTON_TEXT}
        </ForceGenerateButton>
        <SubmitButton
          size="small"
          variant="contained"
          color="success"
          onClick={handleSubmit}
          aria-label="Submit"
          loading={
            priceData.some((item) => item.submitting) && submitStatus === null
          }
          disabled={submitStatus !== null || !isSubmitReady}
          status={submitStatus}
        >
          {submitStatus === constants.SUCCESS
            ? FORCE_GENERATE_BUTTON_TEXT_SUCCESS
            : submitStatus === constants.FAILURE
            ? FORCE_GENERATE_BUTTON_TEXT_FAILURE
            : "Submit"}
        </SubmitButton>
      </div>
    </div>
  );
};

interface ButtonProps {
  status?: "SUCCESS" | "FAILURE" | null;
}

const BaseButton = styled(LoadingButton)(() => ({
  lineHeight: "0.75",
  minWidth: "48px",
  height: "24px",
  padding: "4px 16px",
  borderRadius: "4px",
  textTransform: "none",
  fontSize: 12,
}));

const SubmitButton = styled(BaseButton)((props: ButtonProps) => ({
  border:
    props.status === constants.SUCCESS
      ? "2px solid green !important"
      : props.status === constants.FAILURE
      ? "1px solid red !important"
      : "unset",
  color:
    props.status === constants.SUCCESS
      ? "green !important"
      : props.status === constants.FAILURE
      ? "red !important"
      : "white",
}));

const ForceGenerateButton = styled(BaseButton)((props: ButtonProps) => ({
  border:
    props.status === constants.SUCCESS
      ? "2px solid green !important"
      : props.status === constants.FAILURE
      ? "1px solid red !important"
      : "1px solid var(--Primary-Green-Alt, #008001) !important",
  color:
    props.status === constants.SUCCESS
      ? "green !important"
      : props.status === constants.FAILURE
      ? "red !important"
      : "var(--Primary-Green-Alt, #008001)",
  cursor: props.status !== null ? "not-allowed !important" : "pointer",
}));

export default ActionBar;
